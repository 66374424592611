import React, { Suspense,  } from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';
import { useSelector,} from 'react-redux';
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
// import { fetchData } from '../../slices/authSlice/dataSlice';
// import { fetchUserControlData } from '../../slices/authSlice/getControlSlice';

const App = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const routing = useRoutes(Themeroutes);
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);
  // const { user: currentUser } = useSelector((state) => state.user);
  // const { token: Usertoken } = useSelector((state) => state.user);
  // const { user: currentUser } = useSelector((state) => state.user);
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = () => {
    navigate.go(1);
  };
  // useEffect(() => {
  //   dispatch(fetchUserData({ Usertoken, currentUser }));
  // }, [Usertoken]);
  setInterval(()=> {
    const now = new Date();
    const hour = now.getHours();
    const minute = now.getMinutes();
    if (hour == 0 && minute == 0) {
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('token');
      sessionStorage.clear();
    }    
  }, 30 * 1000);
  
  // useEffect(() => {
  //   // Event listener to handle the beforeunload event
  //   const handleBeforeUnload = (event) => {
  //     console.log(event);
  //     if (event.type === 'beforeunload') {
  //       // Perform any cleanup tasks, like clearing location storage
  //       sessionStorage.clear();
  //       // The message below is optional, but it will prompt the user with a confirmation dialog
  //       event.returnValue = 'Are you sure you want to leave?';
  //     }
  //   };
  //   // Add the event listener when the component mounts
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  return (
    <Suspense fallback={<Loader />}>
      <div
        className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
        dir={direction ? 'rtl' : 'ltr'}>
        <ThemeSelector />
        {routing}
      </div>
    </Suspense>
  );
};

export default App;
