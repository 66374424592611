
const CryptoJS = require('crypto-js');
/* eslint-disable */

const aesKey = process.env.REACT_APP_AES_KEY
 
// function generateUniqueTabId() {
//   return `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
// }
// let tabId = sessionStorage.getItem('tabId');
// if (!tabId) {
//   tabId = generateUniqueTabId();
//   sessionStorage.setItem('tabId', tabId);
// }
// const key = process.env.REACT_APP_TOEKEN_KEY;
// const user = JSON.parse(sessionStorage.getItem(`user${tabId}`))
//   ? JSON.parse(
//       CryptoJS.AES.decrypt(JSON.parse(sessionStorage.getItem(`user${tabId}`)), key).toString(
//         CryptoJS.enc.Utf8,
//       ),
//     )
//   : null;
 

export const Encrypt = async (plaintext) => {
  const encryptedTextGCM = await encryptAESGCM(plaintext);
  const encryptedText = await encryptAESGCMGuid(encryptedTextGCM);
  return encryptedText.toString();
};
export const Decrypt = async (ciphertext) => {
  const decryptedText = await decryptAESGCMGuid(ciphertext);
  const decryptedTextGCM = await decryptAESGCM(decryptedText);
  return decryptedTextGCM.toString();
};
export const encryptAESGCM = async (plaintext) => {
  // console.log(plaintext)
  try {
    // Decode AES key from Base64
    const keyBytes = new Uint8Array(
      atob(aesKey)
        .split('')
        .map((c) => c.charCodeAt(0)),
    );
    // Import key
    const encodedKey = await window.crypto.subtle.importKey(
      'raw',
      keyBytes,
      { name: 'AES-GCM' },
      false,
      ['encrypt'],
    );
    // Generate IV
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    // Encrypt plaintext
    const encryptedData = await window.crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: iv,
      },
      encodedKey,
      new TextEncoder().encode(plaintext),
    );
    // Combine IV and encryptedData into a single Uint8Array
    // console.log(encryptedData);
    const ciphertext = new Uint8Array(iv.length + new Uint8Array(encryptedData).length);
    ciphertext.set(iv);
    ciphertext.set(new Uint8Array(encryptedData), iv.length);
    // Encode ciphertext as Base64
    return btoa(String.fromCharCode(...ciphertext));
  } catch (error) {
    console.error('Encryption error:', error);
    throw error; // Rethrow the error to handle it outside
  }
};

export const decryptAESGCM = async (ciphertext) => {
  try {
    const decodedCiphertext = Uint8Array.from(atob(ciphertext), (c) => c.charCodeAt(0));
    const iv = decodedCiphertext.slice(0, 12);
    const encryptedData = decodedCiphertext.slice(12);
    const encodedKey = await window.crypto.subtle.importKey(
      'raw',
      Uint8Array.from(atob(aesKey), (c) => c.charCodeAt(0)),
      'AES-GCM',
      false,
      ['decrypt'],
    );
    const decryptedData = await window.crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: iv,
      },
      encodedKey,
      encryptedData,
    );
    const decryptedText = new TextDecoder().decode(decryptedData);
    // console.log('DATA', decryptedText.toString(...decryptedText));
    return decryptedText.toString(decryptedText);
  } catch (error) {
    console.error('Decryption error:', error);
  }
};

// EncryptPreLogin
export const EncryptPreLogin = async (plaintext) => {
  const encryptedText = await encryptAESGCM(plaintext);
  return encryptedText.toString();
};
export const DecryptPreLogin = async (ciphertext) => {
  const decryptedText = await decryptAESGCM(ciphertext);
  return decryptedText.toString();
};

const uderGuid = 'd2d32b53-4975-4d13-9542-1effc49904f2';

function guidToBytes(guid) {
  // Remove hyphens from the GUID
  const hex = guid.replace(/-/g, '');
  // Validate the length of the hex string
  if (hex.length !== 32) {
    throw new Error('Invalid GUID format');
  }
  // Convert hex string to byte array
  const bytes = [];
  for (let i = 0; i < hex.length; i += 2) {
    const byte = parseInt(hex.substr(i, 2), 16);
    if (isNaN(byte)) {
      throw new Error('Invalid hex character found');
    }
    bytes.push(byte);
  }
  return bytes;
}
const hashTo32Bytes = (bytes) => {
  const hash = CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(bytes));
  return CryptoJS.enc.Base64.stringify(hash);
};
export const encryptAESGCMGuid = async (plaintext) => {
  try {
    // Decode AES key from Base64
    const bytes = guidToBytes(uderGuid);
    const tempAESKEY = await hashTo32Bytes(bytes);
    const keyBytes = new Uint8Array(
      atob(tempAESKEY)
        .split('')
        .map((c) => c.charCodeAt(0)),
    );
    // Import key
    const encodedKey = await window.crypto.subtle.importKey(
      'raw',
      keyBytes,
      { name: 'AES-GCM' },
      false,
      ['encrypt'],
    );
    // Generate IV
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    // Encrypt plaintext
    const encryptedData = await window.crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: iv,
      },
      encodedKey,
      new TextEncoder().encode(plaintext),
    );
    // Combine IV and encryptedData into a single Uint8Array
    // console.log(encryptedData);
    const ciphertext = new Uint8Array(iv.length + new Uint8Array(encryptedData).length);
    ciphertext.set(iv);
    ciphertext.set(new Uint8Array(encryptedData), iv.length);
    // Encode ciphertext as Base64
    return btoa(String.fromCharCode(...ciphertext));
  } catch (error) {
    console.error('Encryption error:', error);
    throw error; // Rethrow the error to handle it outside
  }
};
export const decryptAESGCMGuid = async (ciphertext) => {
  try {
    const bytes = guidToBytes(uderGuid);
    const tempAESKEY = await hashTo32Bytes(bytes);
    const decodedCiphertext = Uint8Array.from(atob(ciphertext), (c) => c.charCodeAt(0));
    const iv = decodedCiphertext.slice(0, 12);
    const encryptedData = decodedCiphertext.slice(12);
    const encodedKey = await window.crypto.subtle.importKey(
      'raw',
      Uint8Array.from(atob(tempAESKEY), (c) => c.charCodeAt(0)),
      'AES-GCM',
      false,
      ['decrypt'],
    );
    const decryptedData = await window.crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: iv,
      },
      encodedKey,
      encryptedData,
    );
    const decryptedText = new TextDecoder().decode(decryptedData);
    // console.log('DATA', decryptedText.toString(...decryptedText));
    return decryptedText.toString(decryptedText);
  } catch (error) {
    console.error('Decryption error:', error);
  }
};
// async function deriveAESGCMKeyFromGUID(guid) {
//   try {
//     const guidUint8Array = Uint8Array.from(atob(guid), (c) => c.charCodeAt(0));
//     const hashBuffer = await window.crypto.subtle.digest('SHA-256', guidUint8Array);
//     const aesGCMKey = hashBuffer.slice(0, 32);
//     // const key = await window.crypto.subtle.importKey(
//     //   'raw',
//     //   hashBuffer.slice(0, 32),
//     //   { name: 'AES-GCM' },
//     //   false,
//     //   ['encrypt', 'decrypt'],
//     // );
//     return aesGCMKey;
//   } catch (error) {
//     console.error('Error deriving AES-GCM key from GUID:', error);
//     throw error;
//   }
// }
