import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SimpleBar from "simplebar-react";
import {
  Navbar,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Row,
  Col,
  Table,
  ModalHeader,
  Modal,
  ModalBody,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Icon from "react-feather";
import Logo from "../logo/Logo";
import {
  ToggleMiniSidebar,
  ToggleMobileSidebar,
} from "../../store/customizer/CustomizerSlice";
import ProfileDD from "./ProfileDD";
// import qr from '../../assets/images/icons/qr.png';
import phonepe from "../../assets/images/icons/phonepe.png";
import gpay from "../../assets/images/icons/gpay.png";
import paytm from "../../assets/images/icons/paytm.png";
import upi from "../../assets/images/icons/upi.png";
// import qrImage from '../../assets/images/download.png';
import { logout } from "../../slices/authSlice/userSilce";
import MessageDD from "./MessageDD";
import NotificationDD from "./NotificationDD";

// import UserBalance from '../../components/common/userBalance';
import { fetchData } from "../../slices/authSlice/dataSlice";
import user1 from "../../assets/images/users/user1.jpg";
import mobLogo from "../../assets/images/logos/logo-mob.png"
import ReactStrapModal from "../../components/common/reactStrapModal";
import CreditSelfAdmin from "../../views/Admin/ManageCreditDetails/CreditSelfAdmin";

const Header = () => {
  const isDarkMode = useSelector((state) => state.customizer.isDark);
  const topbarColor = useSelector((state) => state.customizer.topbarBg);
  const { user: currentUser } = useSelector((state) => state.user);
  const { token: Usertoken } = useSelector((state) => state.user);
  const [openWalletModal, setOpenWalletModal] = useState(false);
  // const userDeleteResponse = useSelector((state) => state.userdetails.userDeleteSucces);
  // const UpdateCreditResponse = useSelector((state) => state.CreditDebit.commonDeleteSucces);
  // const balanceUpdate = useSelector((state) => state.userdetails.userDeleteSuccess);
  // const controlData = useSelector((state) => state.controlData.items);
  // const userData = useSelector((state) => state.userData.user);
  // const [userBalance, setUserBalance] = useState(0);
  const [modal, setModal] = useState(false);
  const [loading] = useState(false);
  const [proData] = useState(0);

  const baseB4 = "data:image/jpeg;base64,";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showBalance, setshowBalance] = useState(false);

  useEffect(() => {
    dispatch(fetchData({ Usertoken, currentUser }));
  }, [dispatch]);

  const loginData = useSelector((state) => state.data.items);

  const handleLogoutClick = () => {
    window.addEventListener("beforeunload", () => {
      dispatch(logout());
    });
    navigate("/", { replace: true });
    window.location.reload();
  };
  //
  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <Navbar
        color={
          currentUser.role == "1" ||
          currentUser.role == "2" ||
          currentUser.role == "8"
            ? topbarColor
            : topbarColor
        }
        dark={!isDarkMode}
        light={isDarkMode}
        expand="lg"
        className={`${
          currentUser.role == "1" ||
          currentUser.role == "2" ||
          currentUser.role == "8"
            ? "topbarAdmin admin_head_bg"
            : "topbar"
        } ${
          currentUser.role == "1" || currentUser.role == "2" ? "px-md-2" : ""
        }`}
        loading={loading}
      >
        {/********Logo*******/}
        <div className="d-none d-lg-flex align-items-center">
          {currentUser.role == "1" || currentUser.role == "2" ? (
            <Link to="/Dashboards/AdminDashboard">
              <Logo />
            </Link>
          ) : null}
          {currentUser.role !== "1" || currentUser.role !== "2" ? (
            <Button
              close
              size="sm"
              className="ms-auto d-sm-block d-lg-none"
              onClick={() => dispatch(ToggleMobileSidebar())}
            />
          ) : null}
        </div>
        {/******************************/}
        {/**********Toggle Buttons**********/}
        {/******************************/}
        {currentUser.role !== "1" ? (
          <div className="d-flex align-items-center">
            <Button
              color={topbarColor}
              className="d-none d-lg-block mx-2 border-0 hov-dd"
              onClick={() => dispatch(ToggleMiniSidebar())}
            >
              <Icon.Menu size={18} />
            </Button>
            <Button
              color={topbarColor}
              className="d-sm-block d-lg-none border-0 mx-4 hov-dd"
              onClick={() => dispatch(ToggleMobileSidebar())}
            >
              <i className="bi bi-list" />
            </Button>
          </div>
        ) : null}

        {currentUser.role == "1" ? (
          <div className="d-flex">
            <Link to="/Dashboards/AdminDashboard" className="d-sm-block d-lg-none">
               <img src={mobLogo} width={40} alt=""  />
            </Link>
            <Button
              color={topbarColor}
              className="d-sm-block d-lg-none border-0 mx-4 hov-dd"
              onClick={() => dispatch(ToggleMobileSidebar())}
            >
              <i className="bi bi-list" />
            </Button>
          </div>
        ) : null}

        {currentUser.role == "1" ? (
          <>
          <Nav className="text-center d-flex flex-row" navbar>
            <NavItem className="d-md-block d-none">
              <Link
                to="#"
                className={`nav-link hov-dd ${
                  topbarColor === "white" ? "text-dark" : ""
                }`}
              >
                <h4 className="m-auto fw-normal">
                  {" "}
                  Dashboard - WELCOME, {loginData?.username}!
                </h4>
              </Link>
            </NavItem>
          </Nav>
           <Nav
           className="text-center d-flex flex-row align-items-center"
           navbar
         >
           <NavItem className="d-md-block d-none" onClick={() => setOpenWalletModal(true)}>
             <i className="bi bi-wallet2 text-dark fs-3" />
           </NavItem>
         </Nav>
         </>
        ) : (
          <Nav className="m-auto d-flex flex-row" navbar>
            <NavItem className="d-md-block d-none">
              <Link
                to="#"
                className={`nav-link hov-dd ${
                  topbarColor === "white" ? "text-dark" : ""
                }`}
              >
                <h4 className="m-auto fw-normal">
                  {" "}
                  Dashboard - WELCOME, {loginData?.username}!
                </h4>
              </Link>
            </NavItem>
          </Nav>
        )}
        <div className="d-flex align-items-center">
          <div
            className="pt-2 px-1"
            onClick={() => setshowBalance(!showBalance)}
          >
            <h3 className="text-warning fw-normal balance-font">
              {/* Balance : {`\u20B9`} {loginData?.main_Wallet_Balance} */}
              {currentUser.userName === "PFRT000009" ? null : (<>Balance
              {showBalance && (
                <span className="balance-amount">
                  : {`\u20B9`} {loginData?.main_Wallet_Balance}
                </span>
              )}</>)}
              
            </h3>
          </div>
          {/******************************/}
          {/**********Mega DD**********/}
          {/******************************/}

          {/******************************/}
          {/**********Notification DD**********/}
          {/******************************/}
          {currentUser.RoleName !== "Admin" ? (
            // <UncontrolledDropdown className="mx-1 hov-dd d-md-block d-none">
            <UncontrolledDropdown className="mx-1 hov-dd   d-none">
              <DropdownToggle
                className="bg-transparent border-0"
                color={topbarColor}
              >
                <Icon.MessageSquare size={18} />
              </DropdownToggle>
              <DropdownMenu className="ddWidth">
                <div className="bg-info p-3 text-white rounded-top">
                  Notification
                </div>
                <SimpleBar style={{ maxHeight: "350px" }}>
                  <NotificationDD />
                </SimpleBar>
                <DropdownItem divider />
                <div className="p-2 px-3">
                  <Button color="info" size="sm" block>
                    Check All
                  </Button>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : null}
          {/******************************/}
          {/**********Message DD**********/}
          {/******************************/}
          {currentUser.RoleName !== "Admin" ? (
            // <UncontrolledDropdown className="mx-1 hov-dd d-md-block d-none">
            <UncontrolledDropdown className="mx-1 hov-dd   d-none">
              <DropdownToggle
                className="bg-transparent border-0"
                color={topbarColor}
              >
                <Icon.Mail size={18} />
              </DropdownToggle>
              <DropdownMenu className="ddWidth">
                <div className="bg-danger p-3 text-white rounded-top">
                  Messages
                </div>
                <SimpleBar style={{ maxHeight: "350px" }}>
                  <MessageDD />
                </SimpleBar>
                <DropdownItem divider />
                <div className="p-2 px-3">
                  <Button color="danger" size="sm" block>
                    Check All
                  </Button>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : null}
          {/******************************/}
          {/**********Profile DD**********/}
          {/******************************/}
          <UncontrolledDropdown>
            <DropdownToggle color="transparent" className=" hov-dd">
              <img
                src={baseB4 + proData}
                alt="user"
                width="30"
                className="rounded-circle d-none"
              />
              <span className="text-info">
                {loginData?.firstName} {loginData?.lastName}
              </span>
              <img
                src={user1}
                alt="user"
                width="30"
                className="rounded-circle mx-1"
              />
              <Icon.ChevronDown size={15} />
            </DropdownToggle>
            <DropdownMenu className="ddWidth profile-dd">
              <ProfileDD />
              {/* <Link to="/Profile/Certificate" className="text-decoration-none ">
                <DropdownItem className="px-3 border-bottom pt-2 pb-3 d-flex">
                  Certificate
                </DropdownItem>
              </Link> */}
              <div className="p-2 px-3">
                <Button color="danger" size="sm" onClick={handleLogoutClick}>
                  Logout
                </Button>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Navbar>
      {modal ? (
        <Col xs="8" md="12">
          <Modal isOpen={toggle} fade={false} toggle={toggle.bind(null)}>
            <ModalHeader
              toggle={toggle.bind(null)}
              style={{ padding: "10px", color: "#000" }}
            >
              UPI Collection
            </ModalHeader>
            <ModalBody>
              <Row className="row">
                <Col md="4">
                  {/* <img
                    src={baseB4 + QRimage}
                    alt="123"
                    style={{ width: '150px', border: '1px solid #dfe2e6' }}
                  /> */}
                </Col>
                <Col md="8" className="mt-0">
                  <Table className="table-bordered">
                    <thead>
                      <tr>
                        <td className="text-nowrap text-black">Name </td>
                      </tr>
                      <tr>
                        <td className="text-nowrap text-black">UPI ID </td>
                        {/* <td>{UPIID}</td> */}
                      </tr>
                    </thead>
                  </Table>
                  <Row>
                    <img
                      src={phonepe}
                      alt="phonepe"
                      style={{ width: "60px" }}
                    />
                    <img src={gpay} alt="gpay" style={{ width: "60px" }} />
                    <img src={upi} alt="upi" style={{ width: "60px" }} />
                    <img src={paytm} alt="paytm" style={{ width: "60px" }} />
                  </Row>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Col>
      ) : null}
      {openWalletModal && (
        <ReactStrapModal
          modal={openWalletModal}
          setModal={() => setOpenWalletModal(false)}
          isHeader
          isToggle
          title="Self TopUp"
          className="editModelDmt"
          size='sm'
          styles={{width: '400px'}}
        >
          <CreditSelfAdmin setOpenWalletModal={() => setOpenWalletModal(false)} />
        </ReactStrapModal>
      )}
    </>
  );
};

export default Header;
