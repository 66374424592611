import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SuperAdminLayout from '../layouts/SuperAdminLayout';

function ProtectRouteSuperAdmin() {
//   const { token: Usertoken } = useSelector((state) => state.user);
  const { user: currentUser } = useSelector((state) => state.user);
  return currentUser.role === '8'  ? <SuperAdminLayout /> : <Navigate to="/Login" />;
}


export default ProtectRouteSuperAdmin