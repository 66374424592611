import React from "react";
import "./loader.scss";
// import { Spinner } from "reactstrap";
// import loderlogoImg from "../../assets/images/loader/logo.png"
const Loader = () => (
  <div className="fallback-spinner">
    <div className="loading">
    {/*<Spinner color="primary" />
       <img src={loderlogoImg} width={100} alt=""/>
  */}
      
    </div>
  </div>
);
export default Loader;
