import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

function ReactStrapModal(props) {
  const {
    modal,
    setModal,
    isToggle = false,
    children,
    isHeader,
    title,
    isFooter,
    isCancel,
    backdrop="static",
    footerContent,
    className,
    size = 'lg',
    styles = {},
  } = props;
  const toggle = () => setModal(!modal);
  const config = isToggle ? {toggle} : ''
  return (
    <Modal
      isOpen={modal}
      toggle={() => setModal(!modal)}
      className={className}
      backdrop={backdrop}
      size={size}
      style={styles}
    >
      {isHeader && <ModalHeader {...config} className='text-dark fw-bolder'>{title}</ModalHeader>}
      {children && (
        <ModalBody className="p-0">
          <Card className="p-0 mb-0">
            <CardBody className="p-3 w-100">{children}</CardBody>
          </Card>
        </ModalBody>
      )}
      {isFooter && (
        <ModalFooter>
          {footerContent}
          {isCancel && (
            <Button color="danger" onClick={() => setModal(!modal)}>
              Cancel
            </Button>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
}

ReactStrapModal.propTypes = {
  children: PropTypes.node,
  isHeader: PropTypes.bool,
  isToggle: PropTypes.bool,
  isFooter: PropTypes.bool,
  footerContent: PropTypes.node,
  isCancel: PropTypes.bool,
  modal: PropTypes.bool,
  backdrop: PropTypes.bool,
  setModal: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
  styles: PropTypes.object,
};

export default ReactStrapModal;
