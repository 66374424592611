import { createSlice } from '@reduxjs/toolkit';
import { createEncryptedAsyncThunk } from '../Utility/APIUtils';

const initialState = {
  transferSuccess: false,
  creditAmountResponse: {
    error: false,
    message: '',
  },
  commonDeleteResponse: {
    status: false,
    message: '',
  },
  commonDeleteSucces: false,
  error: '',
  loading: false,
};
export const fundRequest = createEncryptedAsyncThunk(
  'transferRequestSlice/fundRequest',
  'Wallet/CreateFundTransferRequest',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      req_mode: Number(params.selected),
      req_from: `${params.currentUser.userId}`,
      req_to: `${params.requestTo}`,
      from_bank_id: params.bankSelect ? params.bankSelect.value : 0,
      to_bank_id: params.fromSelectBank.value,
      transaction_id: params.transactionID,
      deposite_date: params.date ? params.date : params.date1,
      transaction_proof: params.proof,
      amount: Number(params.requestAmount),
      less_advance_amt: 0,
      approved_amt: 0,
      remark: '',
      status: 0,
      payment_status: 0,
      request_remark: params.comments,
    }),
  },
);
export const getTopupReportRetailer = createEncryptedAsyncThunk(
  'transferRequestSlice/getTopupReportRetailer',
  'Wallet/GetFundTransferRequestDetail',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      req_from: params.currentUser.userId,
      req_to: 0,
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  },
);
export const getTopupAdminApproveReject = createEncryptedAsyncThunk(
  'transferRequestSlice/getTopupAdminApproveReject',
  'Wallet/GetFundTransferRequestDetail',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      req_from: params.selectDistributor.value,
      req_to: params.currentUser.userId,
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  },
);
export const getTopupAdminPending = createEncryptedAsyncThunk(
  'transferRequestSlice/getTopupAdminPending',
  'Wallet/GetFundTransferRequestDetail',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      req_from: params.selectDistributor.value,
      req_to: params.currentUser.userId,
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  },
);
export const getTopupReportAdmin = createEncryptedAsyncThunk(
  'transferRequestSlice/getTopupReportAdmin',
  'FundTransfer/GetReqList',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      flag: 'Admin',
      fromdate: params.fromDate,
      todate: params.toDate,
    }),
  },
);
export const approveRejectFundRequest = createEncryptedAsyncThunk(
  'transferRequestSlice/approveRejectFundRequest',
  'Wallet/UpdateFundTransferRequestDetails',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      id: params.modalData.id,
      remark: params.remark,
      status: 1,
      approved_amt: Number(params.approveAmount),
      less_advance_amt: 0,
    }),
  },
);
export const RejectFundRequest = createEncryptedAsyncThunk(
  'transferRequestSlice/RejectFundRequest',
  'Wallet/UpdateFundTransferRequestDetails',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      id: params.data.id,
      remark: 'rejected',
      status: 2,
      approved_amt: 0,
      less_advance_amt: 0,
    }),
  },
);
export const transferRequestSlice = createSlice({
  name: 'transferRequestSlice',
  initialState,
  reducers: {
    creditAmountSuccess(state) {
      return { ...state, creditAmountSucess: true };
    },
    creditAmountFailed(state, action) {
      return { ...state, creditAmountResponse: { error: true, message: action.payload } };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, rechargeResponse: { status: true, message: action.payload } };
    },
    transDeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    transDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    transDeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  creditAmountSuccess,
  creditAmountFailed,
  FetchSuccess,
  FetchFailed,
  transDeleteSuccess,
  transDeleteFailed,
  transDeleteInitial,
} = transferRequestSlice.actions;

export default transferRequestSlice.reducer;
