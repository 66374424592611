import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  EncryptPreLogin,
  DecryptPreLogin,
} from "../../middleware/CryptoMiddleWare";

function generateUniqueTabId() {
  return `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
}
function generateUniqueTabId2() {
  return `${Date.now()}-${Math.random().toString(36).substring(2, 25)}`;
}
// Check if tabId is already in sessionStorage, if not, generate and store it
let tabId = sessionStorage.getItem("tabId");
if (!tabId) {
  tabId = generateUniqueTabId();
  sessionStorage.setItem("tabId", tabId);
}
let tabId2 = sessionStorage.getItem("tabId2");
if (!tabId2) {
  tabId2 = generateUniqueTabId2();
  sessionStorage.setItem("tabId2", tabId2);
}
const CryptoJS = require("crypto-js");

const key = process.env.REACT_APP_TOEKEN_KEY;
const user = JSON.parse(sessionStorage.getItem(`user${tabId}`))
  ? JSON.parse(
      CryptoJS.AES.decrypt(
        JSON.parse(sessionStorage.getItem(`user${tabId}`)),
        key
      ).toString(CryptoJS.enc.Utf8)
    )
  : null;
const token = JSON.parse(sessionStorage.getItem(`token${tabId2}`))
  ? JSON.parse(
      CryptoJS.AES.decrypt(
        JSON.parse(sessionStorage.getItem(`token${tabId2}`)),
        key
      ).toString(CryptoJS.enc.Utf8)
    )
  : null;
const info = JSON.parse(sessionStorage.getItem(`info`))
  ? JSON.parse(
      CryptoJS.AES.decrypt(
        JSON.parse(sessionStorage.getItem(`info`)),
        key
      ).toString(CryptoJS.enc.Utf8)
    )
  : null;
const cfsf = JSON.parse(sessionStorage.getItem(`CfSERTQAER`))
  ? JSON.parse(
      CryptoJS.AES.decrypt(
        JSON.parse(sessionStorage.getItem(`CfSERTQAER`)),
        key
      ).toString(CryptoJS.enc.Utf8)
    )
  : null;
const header = {
  Accept: "application/json",
  PT: process.env.REACT_APP_API_KEY,
  // 'X-CSRF-TOKEN': cfsf.csrfToken,
  "Content-Type": "application/json",
};
const initialState = user
  ? { isLoggedIn: true, user, token, info, cfsf }
  : { isLoggedIn: false, user: "", token: "", info: "", cfsf: "" };
function htmlDecode(input) {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

export const login = createAsyncThunk("user/login", async (params) => {
  const locationInfo = {
    ip: `${params.ipAddress}`,
    deviceId: "",
    deviceType: "Web",
    lattitude: `${params.lat}`,
    longitude: `${params.lng}`,
  };
  const rowData = {
    username: `${params.userName}`,
    password: `${params.userPassword}`,
    role: `${params.selectUserRole}`,
    customAttributes: {
      ip: `${params.ipAddress}`,
      deviceId: "",
      deviceType: params.browserName,
      lattitude: `${params.lat}`,
      longitude: `${params.lng}`,
    },
  };
  // console.log(rowData, "rowData");
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}account/GetToken`,
    {
      method: "POST",
      headers: header,
      body: await EncryptPreLogin(JSON.stringify(rowData)),
    }
  );
  const res = await response.text();
  const data = await DecryptPreLogin(res);
  const encodedString = data;

  const UserData = JSON.parse(htmlDecode(encodedString));
  if (UserData.data.accessToken) {
    const Login = CryptoJS.AES.encrypt(
      JSON.stringify(UserData.data.user),
      key
    ).toString();
    const userToken = CryptoJS.AES.encrypt(
      JSON.stringify(UserData.data.accessToken),
      key
    ).toString();
    const location = CryptoJS.AES.encrypt(
      JSON.stringify(locationInfo),
      key
    ).toString();
    // sessionStorage.setItem('user', JSON.stringify(Login));
    sessionStorage.setItem(`token${tabId2}`, JSON.stringify(userToken));
    sessionStorage.setItem(`user${tabId}`, JSON.stringify(Login));
    sessionStorage.setItem(`info`, JSON.stringify(location));
    return UserData.data;
  }
  if (!res.UserData) {
    return UserData.data;
  }
  return UserData.data;
});

export const validateOTPVerify = createAsyncThunk(
  "user/validateOTPVerify",
  async (params) => {
    const rowData = {
      username: `${params.userName}`,
      password: `${params.userPassword}`,
      role: Number(params.selectUserRole),
      customAttributes: {
        ip: `${params.ipAddress}`,
        deviceId: "string",
        deviceType: "Web",
        lattitude: `${params.lat}`,
        longitude: `${params.lng}`,
      },
      userID: params.currentUser.userGuid,
      otp: params.OTP,
      auth_mode: 1,
      type: "LOGIN",
      mode: "SMS",
      receiverDetails: "",
    };
    // console.log(rowData);
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}Service/VerifyOTP`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          PT: process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${params.Usertoken}`,
        },
        body: await EncryptPreLogin(JSON.stringify(rowData)),
      }
    );
    const res = await response.text();
    const data = await DecryptPreLogin(res);
    const encodedString = data;
    const UserData = JSON.parse(htmlDecode(encodedString));
    if (UserData.data.accessToken) {
      const Login = CryptoJS.AES.encrypt(
        JSON.stringify(UserData.data.user),
        key
      ).toString();
      const userToken = CryptoJS.AES.encrypt(
        JSON.stringify(UserData.data.accessToken),
        key
      ).toString();
      // sessionStorage.setItem('user', JSON.stringify(Login));
      // const location = CryptoJS.AES.encrypt(JSON.stringify(locationInfo), key).toString();
      sessionStorage.setItem(`token${tabId2}`, JSON.stringify(userToken));
      sessionStorage.setItem(`user${tabId}`, JSON.stringify(Login));
      // sessionStorage.setItem(`info`, JSON.stringify(location));
      return UserData.data;
    }
    if (!res.UserData) {
      return UserData.data;
    }
    return UserData.data;
  }
);
export const CheckUserExitforgotPassword = createAsyncThunk(
  "user/CheckUserExitforgotPassword",
  async (params) => {
    const rowData = {
      username: params.UserName,
      authPwd: process.env.REACT_APP_FORGOT_PASSWORD,
    };
    //
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}Service/CheckUserDetailsPreLogin`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          PT: process.env.REACT_APP_API_KEY,
        },
        body: await EncryptPreLogin(JSON.stringify(rowData)),
      }
    );
    const data = await response.text();
    const data1 = await DecryptPreLogin(data);
    const encodedString = data1;
    const res = JSON.parse(htmlDecode(encodedString));
    return res;
  }
);

export const CheckUserRole = createAsyncThunk(
  "user/CheckUserRole",
  async (params) => {
    const rowData = {
      mobNo: params.userName,
      authPassword: process.env.REACT_APP_FORGOT_PASSWORD,
    };
    //
    // console.log(rowData,"rowData")
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}account/GetRoleDetailsByMobNo`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          PT: process.env.REACT_APP_API_KEY,
        },
        body: await EncryptPreLogin(JSON.stringify(rowData)),
      }
    );
    const data = await response.text();
    const data1 = await DecryptPreLogin(data);
    const encodedString = data1;
    const res = JSON.parse(htmlDecode(encodedString));
    return res;
  }
);

export const ForgotPasswordSubmit = createAsyncThunk(
  "user/ForgotPasswordSubmit",
  async (params) => {
    const rowData = {
      otp: params.password,
      auth_mode: 0,
      type: "DEVICE_AUTHORIZATION",
      mode: {
        mode1: "SMS",
        mode2: "",
      },
      receiverDetails: {
        mobileNo: params.userID,
        emailId: "",
      },
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}Service/VerifyPreUserOTP`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          PT: process.env.REACT_APP_API_KEY,
        },
        body: await EncryptPreLogin(JSON.stringify(rowData)),
      }
    );
    const data = await response.text();
    const data1 = await DecryptPreLogin(data);
    const encodedString = data1;
    const res = JSON.parse(htmlDecode(encodedString));
    return res;
  }
);

export const logout = () => {
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("token");
  sessionStorage.clear();
  sessionStorage.clear();
  tabId = sessionStorage.getItem("tabId");
  if (!tabId) {
    tabId = generateUniqueTabId();
    sessionStorage.setItem("tabId", tabId);
  }
  tabId2 = sessionStorage.getItem("tabId2");
  if (!tabId2) {
    tabId2 = generateUniqueTabId2();
    sessionStorage.setItem("tabId2", tabId2);
  }
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginSuccess(state, action) {
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.accessToken,
      };
    },
    loginFailed(state, action) {
      return { ...state, loginResponse: { status: true, msg: action.payload } };
    },
    loginLocation(state, action) {
      return { ...state, info: action.payload };
    },
    loginCfsf(state, action) {
      return { ...state, CfSERTQAER: action.payload.csrfToken };
    },
    setLoginStatesFalse(state) {
      return { ...state, fetchError: false };
    },
    updateToken(state, action) {
      const encryptedToken = CryptoJS.AES.encrypt(
        JSON.stringify(action.payload.payload.data.token),
        key
      ).toString();
      sessionStorage.setItem(`token${tabId2}`, JSON.stringify(encryptedToken));
      return { ...state, token: action.payload.payload.data.token };
    },
  },
});
export const {
  loginSuccess,
  loginToken,
  loginLocation,
  loginCfsf,
  loginFailed,
  updateToken,
} = userSlice.actions;
export default userSlice.reducer;
