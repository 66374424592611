import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import reportWebVitals from './reportWebVitals';
import { store } from './store/Store';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

window.onstorage = () => {
  window.alert('enable you to notify users in real-time about any potential out-of-policy behavior, in order to raise user awareness of the organization s security policy.',);
  sessionStorage.clear();
  window.location.replace('/', { replace: true });
};
root.render(

    <Provider store={store}>
      <BrowserRouter>
        <App />
        <ToastContainer
          bodyClassName="toastBody"
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </BrowserRouter>
    </Provider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
