import React from 'react';
// import loader from '../../assets/images/loader/logo.png';
import loderlogoImg from "../../assets/images/logos/logo.png"
import './loader.css';

const Loader = () => {
  return (
    <div className="container">
      <div className="busy">
          <img src={loderlogoImg} style={{width:"200px"}} alt=""/>            
      </div>
    </div>
  );
};

export default Loader;
