import { createSlice } from "@reduxjs/toolkit";
import { createEncryptedAsyncThunk } from "../../Utility/APIUtils";

const initialState = {
  // creditAmount
  creditAmountSucess: false,
  creditAmountResponse: {
    error: false,
    message: "",
  },
  commonDeleteResponse: {
    status: false,
    message: "",
  },
  commonDeleteSucces: false,
  error: "",
  loading: false,
};
export const getAllServices = createEncryptedAsyncThunk(
  "Commission/getAllServices",
  "Service/GetServiceMaster",
  {
    prepareBody: (params) => ({
      service_id: 0,
      service_name: "",
      userID: params.currentUser.userGuid,
    }),
  }
);
export const createService = createEncryptedAsyncThunk(
  "Commission/createService",
  "Service/CreateServiceMaster",
  {
    prepareBody: (params) => ({
      service_Name: params.body.serviceName,
      api_id: params.selectedAPI.value,
      userID: params.currentUser.userGuid,
    }),
  }
);
export const createServiceForUser = createEncryptedAsyncThunk(
  "Commission/createServiceForUser",
  "Service/CreateServiceMaster",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      service_id: params.serviceId,
    }),
  }
);
export const UpdateMasterStatus = createEncryptedAsyncThunk(
  "Commission/createServiceForUser",
  "Service/UpdateMasterStatus",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      masterID: params.menuList,
      master_Type: params.masterType || 2,
    }),
  }
);
// export const getAllAPI = createEncryptedAsyncThunk(
//   'Commission/getAllAPI',
//   (params) => `Service/GetAPIMaster?userId=${params.currentUser.userId}`,
//   {
//     method: 'GET',
//   },
// );
export const getAppCongfigKeyDetails = createEncryptedAsyncThunk(
  "Commission/getAppCongfigKeyDetails",
  "account/GetAppCongfigKeyDetails",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      master_key: params.masterKey || '',
    }),
  }
);
export const updateAppConfigKeyDetails = createEncryptedAsyncThunk(
  "Commission/updateAppConfigKeyDetails",
  "account/UpdateAppConfigKeyDetails",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      master_key: params.master_key || '',
      status: params.status || false,
      value: params.value || '',
      value1: params.value1 || '',
    }),
  }
);
export const getAllAPI = createEncryptedAsyncThunk(
  "Commission/getAllAPI",
  "Service/GetAPIMaster",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
    }),
  }
);
export const createAPI = createEncryptedAsyncThunk(
  "Commission/createAPI",
  "Service/CreateAPIMaster",
  {
    prepareBody: (params) => ({
      api_name: params.body.apiName,
      api_url: params.body.apiUrl,
      api_details: params.body.apiDetails,
      api_request: params.body.apiRequest,
      api_request_parameter: params.body.apiRequestParam,
      api_response: params.body.apiResponse,
      api_response_parameter: params.body.apiResponseParameter,
      status: 1,
      created_Date: "2024-02-14T12:08:30.755Z",
      created_By: params.currentUser.userId,
      userID: params.currentUser.userGuid,
    }),
  }
);
export const UpdateAPI = createEncryptedAsyncThunk(
  "Commission/UpdateAPI",
  "Service/UpdateAPIMaster",
  {
    prepareBody: (params) => ({
      api_id: params.apiId,
      api_name: params.apiName,
      api_url: params.apiUrl,
      api_details: params.apiDetail,
      api_request: params.apiRequest,
      api_request_parameter: params.apiRequestParam,
      api_response: params.apiResponse,
      api_response_parameter: params.apiResponseParam,
      status: "1",
      created_Date: "2024-02-14T12:08:30.755Z",
      created_By: 0,
      userID: params.currentUser.userGuid,
    }),
  }
);
export const getAllPlans = createEncryptedAsyncThunk(
  "Commission/getAllPlans",
  "Service/GetPlanMaster",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      plan_Id: 0,
      status: 0,
    }),
  }
);
export const getAllCommissionMasterTemplate = createEncryptedAsyncThunk(
  "Commission/getAllCommissionMasterTemplate",
  "Service/GetCommissionMasterTemplate",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      service_Id: params.serviceSelect ? params.serviceSelect.value : 0,
      commission_Id: 0,
      status: 3,
    }),
  }
);

export const GetCommissionMasterTemp = createEncryptedAsyncThunk(
  "Commission/GetCommissionMasterTemp",
  "Service/GetCommissionMasterTemplate",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      service_Id: params.selectedServiceList || [0],
      commission_Id: 0,
      status: 3,
    }),
  }
);

export const UpdateCommissionMasterTemp = createEncryptedAsyncThunk(
  "Commission/UpdateCommissionMasterTemp",
  "Service/UpdateCommissionMasterTemplate",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      // service_id: params.serviceId.serviceId,
      service_id: Number(params.rowToUpdate.service_id),
      commissionMasterTemplate: [
        {
          commission_Id: params.rowToUpdate.commission_Id,
          operator_name: params.rowToUpdate.operator_Name,
          operator_code: params.rowToUpdate.operator_Code,
          charge_type: Number(params.rowToUpdate.charge_Type),
          charges: Number(params.rowToUpdate.charges),
          commission_Type: Number(params.rowToUpdate.commission_Type),
          commission: Number(params.rowToUpdate.commission),
          status: Number(params.rowToUpdate.status),
          min_range: Number(params.rowToUpdate.min_Range),
          max_range: Number(params.rowToUpdate.max_Range),
          gst_type: Number(params.rowToUpdate.gst_Type),
          gst: Number(params.rowToUpdate.gst),
          tds_type: Number(params.rowToUpdate.tds_Type),
          tds: Number(params.rowToUpdate.tds),
        },
      ],
    }),
  }
);

export const getOperatorList = createEncryptedAsyncThunk(
  "Commission/getOperatorList",
  "Service/GetOperatorList",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      api_id: params.e.api_id,
      service_id: params.e.value,
      service_category: params.e.service_Name,
      operator_name: "",
      remark: "TEMPLATE",
    }),
  }
);

export const updateStytemTime = createEncryptedAsyncThunk(
  "Commission/updateStytemTime",
  "account/UpdateMasterData",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      key: params.timeKey,
      value: params.timeValue,
      value1: params.timeValue1,
      value2: "",
      value3: "",
      remark: "",
      status: params.activeStatus,
      tpin: params.tpin,
    }),
  }
);

export const getRechargeOperator = createEncryptedAsyncThunk(
  "RechargeSlice/getRechargeOperator",
  "Service/GetOperatorList",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      api_id: params.e.api_id,
      service_id: params.e.value,
      service_category: "",
      operator_name: "",
      remark: "TEMPLATE",
    }),
  }
);

export const updateStatusByOperator = createEncryptedAsyncThunk(
  "Commission/updateStatusByOperator",
  "account/UpdateOperatorStatus",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      // userID: params.currentUser.userId,
      oplist: params.menuList,
      tpin:""
    }),
  }
);


export const UpdateCommissionMasterTemplate = createEncryptedAsyncThunk(
  "Commission/UpdateCommissionMasterTemplate",
  "Service/UpdateCommissionMasterTemplate",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      service_id: params.serviceSelect ? params.serviceSelect.value : 0,
      commissionMasterTemplate: [
        {
          commission_Id: params.rowToUpdate.commission_Id,
          operator_name: params.rowToUpdate.operator_Name,
          operator_code: params.rowToUpdate.operator_Code,
          charge_type: Number(params.rowToUpdate.charge_Type),
          charges: Number(params.rowToUpdate.charges),
          commission_Type: Number(params.rowToUpdate.commission_Type),
          commission: Number(params.rowToUpdate.commission),
          status: Number(params.rowToUpdate.status),
          min_range: Number(params.rowToUpdate.min_Range),
          max_range: Number(params.rowToUpdate.max_Range),
          gst_type: Number(params.rowToUpdate.gst_Type),
          gst: Number(params.rowToUpdate.gst),
          tds_type: Number(params.rowToUpdate.tds_Type),
          tds: Number(params.rowToUpdate.tds),
        },
      ],
    }),
  }
);

export const updateFundTime = createEncryptedAsyncThunk(
  "Commission/updateFundTime",
  "account/UpdateMasterData",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      key: params.fundKey,
      value: params.fundValue,
      value1: params.fundValue1,
      value2: "",
      value3: "",
      remark: "",
      status: params.activeStatus,
      tpin: params.tpin,
    }),
  }
);

export const CreateCommissionMasterTemplateAPI = createEncryptedAsyncThunk(
  "Commission/CreateCommissionMasterTemplateAPI",
  "Service/CreateCommissionMasterTemplate",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      service_id: params.serviceSelect ? params.serviceSelect.value : 0,
      service_category: params.serviceSelect.label,
      commissionMasterTemplate: [
        {
          operator_name: params.operatorName.label,
          operator_code: params.operatorName.label,
          charge_type: Number(params.chargeType.value),
          charges: Number(params.body.charges),
          commission_Type: Number(params.commissionType.value),
          commission: Number(params.body.commission),
          status: 1,
          min_range: Number(params.body.min_range),
          max_range: Number(params.body.max_range),
          gst_type: Number(params.gstType.value),
          gst: Number(params.body.gst),
          tds_type: Number(params.tdsType.value),
          tds: Number(params.body.tds),
        },
      ],
    }),
  }
);
export const getAllCommissionPlanMaster = createEncryptedAsyncThunk(
  "Commission/getAllCommissionPlanMaster",
  "Service/GetCommissionPlanMaster",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      plan_id: params.planSelect ? `${params.planSelect.value}` : "",
      service_id: params.selectedServiceList,
      plan_role_type: params.selectedRoleList,
      status: 3,
    }),
  }
);
export const UpdateCommissionPlanMaster = createEncryptedAsyncThunk(
  "Commission/UpdateCommissionPlanMaster",
  "Service/UpdateCommissionPlanMaster",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      plan_Id: params.rowToUpdate.plan_id,
      commissionMasterTemplate: [
        {
          commission_Id: params.rowToUpdate.commission_Id,
          operator_name: params.rowToUpdate.operator_Name,
          operator_code: params.rowToUpdate.operator_Code,
          charge_type: Number(params.rowToUpdate.charge_Type),
          charges: Number(params.rowToUpdate.charges),
          commission_Type: Number(params.rowToUpdate.commission_Type),
          commission: Number(params.rowToUpdate.commission),
          status: Number(params.rowToUpdate.status),
          min_range: Number(params.rowToUpdate.min_Range),
          max_range: Number(params.rowToUpdate.max_Range),
          gst_type: Number(params.rowToUpdate.gst_Type),
          gst: Number(params.rowToUpdate.gst),
          tds_type: Number(params.rowToUpdate.tds_Type),
          tds: Number(params.rowToUpdate.tds),
          service_id: Number(params.rowToUpdate.service_id),
        },
      ],
    }),
  }
);
export const CreateCommissionPlanMaster = createEncryptedAsyncThunk(
  "Commission/CreateCommissionPlanMaster",
  "service/CreateCommissionPlanMaster",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      service_id: params.selectedServiceList,
      plan_name: `${params.planSelect.planName}`,
      plan_role_type: params.selctedRoleList,
    }),
  }
);
export const getPlanWiseUser = createEncryptedAsyncThunk(
  "Commission/getPlanWiseUser",
  "UserRegistration/GetPlanWiseUser",
  {
    prepareBody: (params) => ({
      planID: `${params.PlanID}`,
    }),
  }
);
export const createPlan = createEncryptedAsyncThunk(
  "Commission/createPlan",
  "Service/CreatePlanMaster",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      plan_Name: params.body.planName,
    }),
  }
);
export const Commission = createSlice({
  name: "Commission",
  initialState,
  reducers: {
    creditAmountSuccess(state) {
      return { ...state, creditAmountSucess: true };
    },
    creditAmountFailed(state, action) {
      return {
        ...state,
        creditAmountResponse: { error: true, message: action.payload },
      };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return {
        ...state,
        rechargeResponse: { status: true, message: action.payload },
      };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: "" },
      };
    },
  },
});
export const {
  FetchSuccess,
  FetchFailed,
  DeleteSuccess,
  DeleteFailed,
  DeleteInitial,
  creditAmountSuccess,
  creditAmountFailed,
} = Commission.actions;
export default Commission.reducer;
