import { useState, useEffect } from 'react';
import { Nav } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getMenuList } from '../../../slices/admin/Management/managementSlice';
import SidebarData from '../sidebardata/HorizontalSidebarData';
import NavSubItem from './NavSubItem';
import NavSingleItem from './NavSingleItem';

const HorizontalSidebar = () => {
  const activeBg = useSelector((state) => state.customizer.sidebarBg);
  const location = useLocation();
  const dispatch = useDispatch();
  const currentURL = location.pathname.split('/').slice(0, -1).join('/');
  const isFixed = useSelector((state) => state.customizer.isSidebarFixed);
  const isMobileSidebar = useSelector((state) => state.customizer.isMobileSidebar);
  const { user: currentUser } = useSelector((state) => state.user);
  const { token: Usertoken } = useSelector((state) => state.user);
  const [filteredData, setFilteredData] = useState(SidebarData);
  const [menuList, setMenulist] = useState([]);

  useEffect(() => {
    const roleId = 2;
    const selectedBusiness = {
      value : currentUser.userId
    }
    dispatch(getMenuList({ currentUser, Usertoken, roleId , selectedBusiness }))
      .unwrap()
      .then((res) => {
        if (res.status.type === 'success') {
          setMenulist(res.data.filter((item) => item.status));
        } else {
          setMenulist([]);
        }
      })
      .catch(() => {});
  }, []);

  const filterChildren = (children) => {
    return children.filter((child) =>
      menuList.some((d) => d.right_name === child.title.toUpperCase()),
    );
  };

  const filteredSidebarData = SidebarData.reduce((acc, item) => {
    const filteredChildren = item.children ? filterChildren(item.children) : [];
    if (filteredChildren.length > 0) {
      acc.push({
        ...item,
        children: filteredChildren,
      });
    }
    return acc;
  }, []);

  const superSideBar = [
    {
      title: 'Dashboard',
      href: '/Dashboards/SuperAdminDashboard',
      id: 1,
      suffixColor: 'bg-info text-dark-white',
      icon: <i className="bi bi-house"></i>,
    },
    {
      title: 'Commission ',
      href: '#',
      id: 2,
      suffixColor: 'bg-info text-dark-white',
      icon: <i className="bi bi-bag-check"></i>,
      collapisble: true,
      children: [
        {
          title: 'API Master',
          href: '/SuperAdmin/AllApi',
          id: 6,
          suffixColor: '#4cb5bb',
          icon: <i className="bi bi-chevron-right"></i>,
          collapisble: true,
        },
        {
          title: 'Services',
          href: '/SuperAdmin/AllServices',
          id: 6,
          suffixColor: '#4cb5bb',
          icon: <i className="bi bi-chevron-right"></i>,
          collapisble: true,
        },
        {
          title: 'Commission Template',
          href: '/SuperAdmin/GetCommissionMasterTemplate',
          id: 14,
          suffixColor: '#4cb5bb',
          icon: <i className="bi bi-chevron-right"></i>,
          collapisble: true,
        },
      ],
    },
  ];
  useEffect(() => {
    if (currentUser.role == 2) {
      setFilteredData(filteredSidebarData);
    } else if (currentUser.role == 8) {
      setFilteredData(superSideBar);
    }
  }, [menuList]);

  return (
    <div
      className={`horizontalNav shadow bg-hrbar ${isFixed ? 'fixedSidebar' : ''} ${
        isMobileSidebar ? 'showSidebar' : ''
      } ${
        currentUser.role == 1 || currentUser.role == 2 || currentUser.role == '8'
          ? 'admin_sidebar'
          : ''
      }`}
    >
      <div className="container-fluid">
        <Nav className={activeBg === 'white' ? '' : 'lightText' }>
          {filteredData.map((navi, index) => {
            if (navi.caption) {
              return (
                <div
                  className="navCaption fw-bold mt-4 d-none d-sm-block d-md-none"
                  key={navi.caption}
                >
                  {navi.caption}
                </div>
              );
            }
            if (navi.children) {
              return (
                <NavSubItem
                  key={navi.id}
                  index={index}
                  icon={navi.icon}
                  title={navi.title}
                  items={navi.children}
                  suffix={navi.suffix}
                  ddType={navi.ddType}
                  activeBck={activeBg}
                  suffixColor={navi.suffixColor}
                  isUrl={currentURL === navi.href}
                />
              );
            }
            return (
              <NavSingleItem
                key={navi.id}
                //toggle={() => toggle(navi.id)}
                className={location.pathname === navi.href ? 'activeLink' : ''}
                to={navi.href}
                title={navi.title}
                suffix={navi.suffix}
                suffixColor={navi.suffixColor}
                icon={navi.icon}
              />
            );
          })}
        </Nav>
      </div>
    </div>
  );
};

export default HorizontalSidebar;
