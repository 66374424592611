import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SuperLayout from '../layouts/SuperLayout';

function ProtectRouteSuper() {
  // const { token: Usertoken } = useSelector((state) => state.user);
  const { user: currentUser } = useSelector((state) => state.user);
  return currentUser.role === '4'  ? <SuperLayout /> : <Navigate to="/Login" />;
}
export default ProtectRouteSuper;
